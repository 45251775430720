import React, { useEffect, useState } from "react";
import globalAxios from "../../globalAxios.js";
import { SERVER_URL } from "../../config.js";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import {
	setCalendarView,
	setCalendarDate,
	removeSession,
	deleteEventsByMonth,
} from "../../store/slices/calendarSlice.ts";

import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	CircularProgress,
	Grid,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Calendar.css";
import {
	calculateFeeEstimate,
	calculateSinglePtFeeEstimate,
} from "./helper/feeCalculation.ts";
import { determineSessionFundingStatus } from "./helper/fundingStatus.ts";

const Calendar = () => {
	const [open, setOpen] = useState(false);
	const [deleting, setDeleting] = useState(false); // for single event.
	const [deletingEvents, setDeletingEvents] = useState(false); // for current month of events.

	const [selectedEvent, setSelectedEvent] = useState({
		title: "",
		id: "",
		start: new Date(),
		surgeon: "",
		hospital: "",
		events: [],
	});

	const events = useAppSelector((state) => state.calendar.events);
	const currentLens = useAppSelector((state) => state.calendar.lens);
	const currentView = useAppSelector((state) => state.calendar.view);
	const currentDate = useAppSelector((state) => state.calendar.currentDate);
	const isAdmin = useAppSelector((state) => state.user.admin);
	const aliases = useAppSelector((state) => state.user.settings?.aliases);

	const [schedules, setSchedules] = useState([]);
	const extractItemNumbersFromRedux = (events) => {
		const itemNumbers = [];

		events.forEach((event) => {
			event.events.forEach((subEvent) => {
				try {
					// Skip if actual_items is null, undefined, or the string "null"
					if (
						!subEvent.actual_items ||
						subEvent.actual_items === "null"
					) {
						return;
					}

					// Parse actual_items which is in JSON string format
					const parsedItems = JSON.parse(subEvent.actual_items);

					// Skip if parsedItems is not an array (safety check)
					if (!Array.isArray(parsedItems)) {
						return;
					}

					// Filter only numeric items
					const numericItems = parsedItems.filter(
						(item) => !isNaN(item)
					);

					// Collect only numeric item numbers
					itemNumbers.push(...numericItems);
				} catch (error) {
					console.error("Error parsing actual_items:", error);
				}
			});
		});

		// Return unique item numbers
		return [...new Set(itemNumbers)];
	};
	const fetchSchedules = async (itemNumbers) => {
		if (itemNumbers.length === 0) return; // Don't fetch if no item numbers

		try {
			const response = await globalAxios.get(`${SERVER_URL}/schedules`, {
				params: { itemNums: itemNumbers },
			});

			setSchedules(response.data); // Store the fetched schedules
		} catch (error) {
			console.error("Error fetching schedules:", error);
		}
	};

	// Call this after extracting item numbers from Redux
	useEffect(() => {
		const itemNumbers = extractItemNumbersFromRedux(events); // Use the events from Redux
		fetchSchedules(itemNumbers);
	}, [events]); // Re-run when Redux events change

	const dispatch = useAppDispatch();

	const handleEventClick = (clickInfo) => {
		const { title, id, start, extendedProps } = clickInfo.event;

		setSelectedEvent({
			title: title,
			id: id,
			start: start,
			surgeon:
				extendedProps.surgeon.first_name +
				" " +
				extendedProps.surgeon.last_name,
			hospital: extendedProps.hospital.name,
			events: extendedProps.events,
		});

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedEvent({
			title: "",
			id: "",
			start: new Date(),
			surgeon: "",
			hospital: "",
			events: [],
		});
	};

	// const handleEdit = () => {
	// 	console.log('Editing event:', selectedEvent);
	// 	// Implement your edit logic here
	// 	setOpen(false);
	// };

	const handleDelete = async () => {
		console.log("Deleting event:", selectedEvent);

		if (!selectedEvent.id) {
			console.error("No event selected or missing event ID.");
			return;
		}

		setDeleting(true);

		try {
			// Replace `/api/sessions/${selectedEvent.id}` with your actual endpoint that handles session deletion
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions/${selectedEvent.id}`
			);

			if (response.status === 200) {
				console.log("Session deleted successfully.");
				dispatch(removeSession(parseInt(selectedEvent.id)));
			}
		} catch (error) {
			console.error("Error deleting session:", error);
		} finally {
			setDeleting(false);
			setOpen(false);
		}
	};

	const eventDidMount = (info) => {
		// Change the styling from bullet point to block.
		if (info.view.type === "dayGridMonth") {
			const eventDate = info.event.startStr.split("T")[0];
			const eventsOnThisDay = document.querySelectorAll(
				`[data-date="${eventDate}"] .fc-event`
			);
			if (eventsOnThisDay.length > 3) {
				// Hide excess events
				for (let i = 3; i < eventsOnThisDay.length; i++) {
					eventsOnThisDay[i].style.display = "none";
				}
			}
		}
	};

	// Format the event data for use in the render fn.
	const convertEventsFormat = (view, eventsArray, aliases) => {
		const sortedEventsArray = [...eventsArray].sort((a, b) => {
			const dateA = new Date(a.date_of_service + "T" + a.start_time);
			const dateB = new Date(b.date_of_service + "T" + b.start_time);
			return dateA - dateB; // Sort by date first and then by time
		});

		return sortedEventsArray.map((event, index) => {
			const startDate = event.date_of_service;
			const startTime = event.start_time ? `T${event.start_time}:00` : ""; // Add time only if start_time is not null

			const hospitalAlias = aliases?.hospital_aliases?.find(
				(alias) => alias.name === event.hospital.name
			);
			const hospitalDisplayName = hospitalAlias
				? hospitalAlias.preferred_name
				: event.hospital.name;
			const hospitalAcronym = hospitalAlias?.preferred_acronym || "";

			// Add HF Aliases
			// Calculate Fee Estimate
			const processedEvents = event.events.map((subEvent) => {
				const feeEstimate = calculateSinglePtFeeEstimate(
					subEvent,
					schedules
				);

				// Check if subEvent has a health fund in patient.policies[0]
				const healthFund = subEvent.patient?.policies[0]?.healthFund;

				// Find the health fund alias by comparing healthFund.id with alias.id
				const healthFundAlias = healthFund
					? aliases?.healthfund_aliases?.find(
							(alias) => alias.healthfund_id === healthFund.id
					  )
					: null;

				// Use the preferred name and acronym if alias exists, otherwise use the original name
				const healthFundDisplayName = healthFundAlias
					? healthFundAlias.preferred_name
					: healthFund?.aliases[1] || "N/A";
				const healthFundAcronym =
					healthFundAlias?.preferred_acronym || "";

				// Return the modified subEvent, with healthFund set back in its original position
				return {
					...subEvent,
					feeEstimate,
					patient: {
						...subEvent.patient,
						policies: [
							{
								...subEvent.patient.policies[0],
								healthFund: {
									...healthFund,
									acronym: healthFundAcronym,
									name: healthFundDisplayName,
								},
							},
						],
					},
				};
			});
			// Check for surgeon alias
			const surgeonAlias = aliases?.surgeon_aliases?.find(
				(alias) =>
					alias.name ===
					`${event.surgeon.first_name} ${event.surgeon.last_name}`
			);
			const surgeonAcronym = surgeonAlias?.preferred_acronym || "";

			// Get the next event in the array to check for overlap
			const nextEvent = sortedEventsArray[index + 1];

			const formattedEvent = {
				id: event.id,
				title: toCapitalized(event.hospital.name),
				start: startDate + startTime,
				end:
					event.end ||
					calculateEndTime(startDate, event.start_time, nextEvent),
				surgeon: {
					acronym: surgeonAcronym,
					first_name: surgeonAlias
						? surgeonAlias.preferred_name.split(" ")[0]
						: event.surgeon.first_name,
					last_name: surgeonAlias
						? surgeonAlias.preferred_name.split(" ")[1]
						: event.surgeon.last_name,
				},
				hospital: {
					acronym: hospitalAcronym,
					name: hospitalDisplayName,
				},
				events: processedEvents,
				startingTime: event.start_time,
			};

			return {
				...formattedEvent,
				color: setEventColor(view, formattedEvent),
			};
		});
	};

	// Determine and set the event block colour.
	const setEventColor = (view, e) => {
		switch (view) {
			case "location":
				if (e.startingTime === null) {
					return "grey";
				}

				if (e.startingTime < "11:30") {
					return "#1976d2";
				} else if (e.startingTime > "11:30") {
					return "orange";
				} else {
					return "grey";
				}

			case "uploadStatus":
				return "#388e3c";

			case "feeEstimate":
				const estimate = calculateFeeEstimate(e.events, schedules);

				return estimate ? "#388e3c" : "grey";

			case "fundingStatus":
				const allCovered = determineSessionFundingStatus(e.events);

				return allCovered ? "#388e3c" : "grey";

			default:
				return "grey";
		}
	};

	// Styling display for individual events display on the current calendar view.
	const renderEventContent = (eventInfo) => {
		const eProps = eventInfo.event.extendedProps;

		// Define checks.
		const isDayGridMonth = eventInfo.view.type === "dayGridMonth";

		const hospitalAcronym = eProps.hospital.acronym
			? eProps.hospital.acronym
			: abbreviateName(eProps.hospital.name);

		const surgeonAcronym = eProps.surgeon.acronym
			? eProps.surgeon.acronym
			: abbreviateName(
					eProps.surgeon.first_name + " " + eProps.surgeon.last_name
			  );

		// Define the content to display conditionally
		const contentToDisplay = () => {
			switch (currentLens) {
				case "location":
					return {
						content: isDayGridMonth
							? `${
									formatMonthlyViewTime(eventInfo.timeText)
										? formatMonthlyViewTime(
												eventInfo.timeText
										  ) + " - "
										: ""
							  }${hospitalAcronym} - ${surgeonAcronym}`
							: `${eventInfo.timeText} ${eventInfo.event.title}`,
						backgroundColour: setEventColor("location", eProps),
					};

				case "uploadStatus":
					return {
						content: isDayGridMonth
							? `${hospitalAcronym} - ${surgeonAcronym}`
							: `${eventInfo.timeText} ${eventInfo.event.title}`,
						backgroundColour: setEventColor("uploadStatus", eProps),
					};

				case "feeEstimate":
					const estimate = calculateFeeEstimate(
						eProps.events,
						schedules
					);

					return {
						content: estimate
							? `$${parseFloat(estimate.toFixed(2))}, ${
									eProps.events?.length
							  }x Pt. - ${surgeonAcronym}`
							: `N/A, ${eProps.events?.length}x Pt. - ${surgeonAcronym}`,
						backgroundColour: setEventColor("feeEstimate", eProps),
					};

				case "fundingStatus":
					const allCovered = determineSessionFundingStatus(
						eventInfo.event.extendedProps.events
					);

					return {
						content: allCovered
							? "Funding Available"
							: "Funding Missing",
						backgroundColour: setEventColor(
							"fundingStatus",
							eProps
						),
					};

				default:
					return {
						content: `${hospitalAcronym}`,
						backgroundColour: "grey",
					};
			}
		};

		// Get the content and background colour from the contentToDisplay function
		const { content, backgroundColour } = contentToDisplay();

		return (
			<div
				style={{
					// Background colour is for modified monthyl view events.
					backgroundColor: backgroundColour,
					borderColor: backgroundColour,
					color: "white",
					padding: "2px 4px",
					height: "100%",
					width: "100%",
					borderRadius: isDayGridMonth ? "3px" : "0",
					display: isDayGridMonth ? "block" : "inline-block",
				}}
			>
				<div>{content}</div>
			</div>
		);
	};

	//  Used to determine current month to delete all monthly events.
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Track current month
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [isMonthlyView, setIsMonthlyView] = useState(true);

	// Update current month and view type
	const handleDatesSet = (dateInfo) => {
		const { start, view } = dateInfo;

		setCurrentMonth(start.getMonth());
		setCurrentYear(start.getFullYear());
		setIsMonthlyView(view.type === "dayGridMonth");

		dispatch(setCalendarView(view.type));
		dispatch(setCalendarDate(start.toISOString()));
	};

	const handleDeleteEvents = async () => {
		setDeletingEvents(true);

		// Format the date as YYYY-MM
		const formattedDate = `${currentYear}-${(currentMonth + 1)
			.toString()
			.padStart(2, "0")}`;

		// axios request to delete all events within the current month
		try {
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions`,
				{
					data: { date: formattedDate }, // Pass the formatted date as data in the request body
				}
			);
			if (response.status === 200) {
				// Dispatch the Redux action to update the state after deletion
				dispatch(deleteEventsByMonth(formattedDate));
			} else {
				console.error(
					"Failed to delete events:",
					response.data.message
				);
			}
		} catch (e) {
			console.error("Error deleting events:", e);
		} finally {
			setDeletingEvents(false);
		}
	};

	return (
		<Box
			sx={{
				"& .fc": {
					maxHeight: "80vh",
					minHeight: "80vh",
				},
				"& .fc-event": {
					cursor: "pointer",
				},
			}}
		>
			<FullCalendar
				// fixedWeekCount={false}
				showNonCurrentDates={false}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
					interactionPlugin,
				]}
				initialView={currentView}
				initialDate={currentDate}
				slotMinTime="08:00:00" // Start the day at 8 AM
				slotMaxTime="22:00:00"
				slotLabelInterval="05:00:00"
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay listMonth",
				}}
				firstDay={1}
				editable={true}
				events={convertEventsFormat(currentLens, events, aliases)}
				eventClick={handleEventClick}
				eventDidMount={eventDidMount} // Apply hiding logic after event is mounted
				datesSet={handleDatesSet} // Track the current month and view type
				viewDidMount={(viewInfo) => {
					if (viewInfo.view.type === "dayGridMonth") {
						document
							.querySelectorAll(".fc-event")
							.forEach((eventEl) => {
								eventEl.classList.add("fc-daygrid-event");
							});
					}
				}}
				eventContent={renderEventContent}
			/>

			<Grid container>
				{isAdmin && isMonthlyView ? (
					<Grid item xs={2}>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							alignItems="center"
							sx={{ marginTop: "1rem" }}
						>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									disableElevation
									sx={{ color: "white" }}
									onClick={handleDeleteEvents}
									disabled={deletingEvents} // Button is disabled when loading is true
								>
									{deletingEvents ? (
										<CircularProgress
											size={24}
											sx={{ color: "white" }}
										/>
									) : (
										"Delete Events"
									)}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				) : (
					""
				)}
			</Grid>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				PaperProps={{
					sx: {
						maxWidth: "80vw", // Set max-width to 80vw
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					Session Details
					<Button
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						Close
					</Button>
				</DialogTitle>
				<DialogContent>
					<Box>
						<p>
							{selectedEvent?.start
								? new Date(
										selectedEvent.start
								  ).toLocaleDateString()
								: ""}
						</p>
						<p>
							<b>{selectedEvent?.hospital}</b>
						</p>
						<p>
							<b>{selectedEvent?.surgeon}</b>
						</p>
						<p>
							Assistant Estimate:&nbsp;
							<b>
								$
								{`${calculateFeeEstimate(
									selectedEvent.events,
									schedules
								)?.toFixed(2)}`}
							</b>
						</p>
					</Box>
				</DialogContent>
				<DialogActions>
					{/* <Button onClick={handleEdit} color="primary">
						Edit
					</Button> */}
					{deleting ? (
						<Button disabled>
							<svg width={0} height={0}>
								<defs>
									<linearGradient
										id="my_gradient"
										x1="0%"
										y1="0%"
										x2="0%"
										y2="100%"
									>
										<stop offset="0%" stopColor="#e01cd5" />
										<stop
											offset="100%"
											stopColor="#1CB5E0"
										/>
									</linearGradient>
								</defs>
							</svg>
							<CircularProgress
								size={18}
								sx={{
									"svg circle": {
										stroke: "url(#my_gradient)",
									},
								}}
							/>
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={handleDelete}
							color="error"
							size="small"
							disableElevation
						>
							Delete Session
						</Button>
					)}
				</DialogActions>
				<DialogContent>
					<Box>
						{selectedEvent?.events?.length > 0 ? (
							selectedEvent.events.map((event, index) => {
								// Calculate individual patient fee estimate

								return (
									<Accordion key={index}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
										>
											<Typography variant="body1">
												<b>Patient:</b>{" "}
												{event.patient.first_name}{" "}
												{event.patient.last_name} |{" "}
												<b>Procedure:</b>{" "}
												{event.procedure}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography variant="body2">
												DOB:{" "}
												{event.patient.date_of_birth}
											</Typography>
											<Typography variant="body2">
												Medicare:{" "}
												{event.patient.medicare}
											</Typography>
											<Typography variant="body2">
												Health Fund:{" "}
												{Array.isArray(
													event.patient.policies
												) &&
												event.patient.policies.length >
													0
													? event.patient.policies[0]
															.healthFund?.name
														? event.patient
																.policies[0]
																.healthFund.name // Use health fund name if it exists
														: event.patient
																.policies[0]
																.healthFund
																.aliases[1] // Fallback to aliases[1] if no name exists
													: "N/A"}
											</Typography>
											<Typography variant="body2">
												Health Fund #:{" "}
												{Array.isArray(
													event.patient.policies
												) &&
												event.patient.policies.length >
													0
													? event.patient.policies[0]
															.health_fund_no
													: "N/A"}
											</Typography>
											<Typography variant="body2">
												Items:{" "}
												{(() => {
													let itemsArray = [];
													try {
														itemsArray = JSON.parse(
															event.actual_items
														);
													} catch (error) {
														console.error(
															"Error parsing actual_items:",
															error
														);
													}
													return Array.isArray(
														itemsArray
													) && itemsArray.length > 0
														? itemsArray.join(", ")
														: "";
												})()}
											</Typography>
											<Typography variant="body2">
												Anaesthetist:{" "}
												{event.anaesthetist?.name ||
													"N/A"}
											</Typography>
											<br />
											{/* Individual Patient Fee Estimate */}
											<Typography variant="body2">
												Assistant Estimate:&nbsp;
												<b>
													$
													{`${event.feeEstimate?.toFixed(
														2
													)}`}
												</b>
											</Typography>
										</AccordionDetails>
									</Accordion>
								);
							})
						) : (
							<Typography variant="body2">
								No additional events available.
							</Typography>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default Calendar;

const toCapitalized = (str) => {
	return str
		.split(" ")
		.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(" ");
};

const abbreviateName = (name) => {
	return name
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase())
		.join("");
};

// Convert month view time text to 24-hour format
const formatMonthlyViewTime = (timeText) => {
	if (!timeText) return "";
	const timeRegex = /^(\d{1,2}):?(\d{2})?(a|p)$/;
	const match = timeText.match(timeRegex);
	if (match) {
		let [hours, minutes = "00", period] = [match[1], match[2], match[3]];
		hours = parseInt(hours, 10);
		if (period === "p" && hours !== 12) {
			hours += 12;
		} else if (period === "a" && hours === 12) {
			hours = 0;
		}
		return `${hours.toString().padStart(2, "0")}${minutes}`;
	}
	return timeText; // Return as-is if not matched
};

// Calculate end time for each calendar event to display block length in weekly view.
const calculateEndTime = (startDate, startTime, nextEvent) => {
	if (!startTime) {
		return null;
	}

	const startHour = parseInt(startTime.split(":")[0], 10);
	const startMinute = parseInt(startTime.split(":")[1], 10);
	const startDateTime = new Date(`${startDate}T${startTime}`);

	let defaultEndTime;

	// Determine the default end time based on start time ranges
	if (
		(startHour >= 6 && startHour < 11) ||
		(startHour === 11 && startMinute <= 30)
	) {
		defaultEndTime = new Date(`${startDate}T18:00:00`);
	} else if (
		(startHour >= 12 && startHour < 17) ||
		(startHour === 17 && startMinute <= 30)
	) {
		defaultEndTime = new Date(`${startDate}T22:00:00`);
	} else if (startHour >= 17 && startHour < 20) {
		defaultEndTime = new Date(`${startDate}T22:00:00`);
	} else {
		defaultEndTime = new Date(startDateTime);
	}

	// Check for overlap with the next event
	if (nextEvent) {
		const nextEventStartDateTime = new Date(
			`${nextEvent.date_of_service}T${nextEvent.start_time}:00`
		);
		if (defaultEndTime > nextEventStartDateTime) {
			// If the default end time causes an overlap, apply the overlap rules
			if (
				(startHour >= 6 && startHour < 11) ||
				(startHour === 11 && startMinute <= 30)
			) {
				defaultEndTime = new Date(`${startDate}T12:30:00`);
			} else if (
				(startHour >= 12 && startHour < 17) ||
				(startHour === 17 && startMinute <= 30)
			) {
				defaultEndTime = new Date(`${startDate}T17:30:00`);
			}
		}
	}

	// Convert UTC to local time directly using Date methods
	const localEndTime = new Date(
		defaultEndTime.getTime() - defaultEndTime.getTimezoneOffset() * 60000
	);

	return localEndTime.toISOString().slice(0, -1); // Remove the 'Z' to reflect local time without the UTC indicator
};
