import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Box, Tabs, Tab } from "@mui/material";

import OverlayContent from "./overlay/OverlayContent.tsx";
import LensView from "./m-calendar/LensView.tsx";

import EventIcon from "@mui/icons-material/Event";
import ApprovalIcon from "@mui/icons-material/Approval";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import { useAppSelector } from "../../store/hooks.ts";
import ExcelGenerator from "../excelgenerator/ExcelGenerator.tsx";
import Legend from "./m-calendar/Legend.tsx";
import Search from "./m-settings/search.tsx";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const MiniNav = (props) => {
	const [value, setValue] = useState(0);
	const location = useLocation(); // Get current path

	// Reset tab value to `0` when the URL path changes
	useEffect(() => {
		// Reset the tab index to 0 when navigating to a new path
		setValue(0);
	}, [location.pathname]);

	const dialogOpen = useAppSelector((state) => state.miniNav.dialogOpen);

	const boxRef = useRef<HTMLDivElement | null>(null);
	const [boxHeight, setBoxHeight] = useState(0);
	const updateBoxHeight = () => {
		if (boxRef.current) {
			setBoxHeight(boxRef.current.clientHeight);
		}
	};
	useEffect(() => {
		updateBoxHeight(); // Set initial height

		window.addEventListener("resize", updateBoxHeight); // Update height on window resize
		return () => {
			window.removeEventListener("resize", updateBoxHeight); // Cleanup event listener
		};
	}, []);

	const activeTab = useAppSelector((state) => state.settings.activeTab); // Get activeTab from Redux

	// Function to dynamically return the icon and component for the settings tabs
	const getSettingsIconAndComponent = () => {
		switch (activeTab) {
			case 0:
				return {
					icon: null,
					component: null,
				};
			case 1:
				return {
					icon: <ManageSearchIcon fontSize="large" />,
					component: <Search miniNavRef={boxRef} />,
				};
		}
	};

	// Icon and component mapping based on URL paths
	const panelMapping = {
		"/": [
			{
				icon: <EventIcon fontSize="large" />,
				label: "Lens View",
				component: <LensView />,
			},
			{
				icon: <FileDownloadIcon fontSize="large" />,
				label: "Export File",
				component: <ExcelGenerator />,
			},
			{
				icon: <InfoIcon fontSize="large" />,
				label: "Legend",
				component: <Legend />,
			},
		],
		"/calendar": [
			{
				icon: <EventIcon fontSize="large" />,
				label: "Calendar View",
				component: <LensView />,
			},
			{
				icon: <FileDownloadIcon fontSize="large" />,
				label: "Export File",
				component: <ExcelGenerator />,
			},
			{
				icon: <InfoIcon fontSize="large" />,
				label: "Legend",
				component: <Legend />,
			},
		],
		"/settings": [getSettingsIconAndComponent()],
		"/profile": [
			{
				icon: <ApprovalIcon fontSize="large" />,
				label: "Profile",
				component: <span>hello</span>,
			},
		],
		// Add more paths and icons as needed
	};

	// Get icons and content components based on current URL path
	const iconsAndComponents = panelMapping[location.pathname] || [];

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box
			ref={boxRef}
			sx={{
				flexGrow: 1,
				bgcolor: "background.paper",
				display: "flex",
				borderRight: "solid 2px #B4C9D0",
				...props.style,
			}}
		>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				ScrollButtonComponent={() => null}
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{
					borderRight: 1,
					borderColor: "divider",
					width: "50px",
					minWidth: "50px", // Prevent shrinking
					maxWidth: "50px",
					background: "#C5E5F0",
					"& .MuiTabs-flexContainer": {
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "center",
						height: "100%",
					},
				}}
			>
				{iconsAndComponents.map(
					(item, index) =>
						item.icon && (
							<Tab
								key={index}
								icon={item.icon}
								{...a11yProps(index)}
								sx={{ marginTop: "10px", marginBottom: "10px" }} // Add spacing between the icons
							/>
						)
				)}
			</Tabs>

			{iconsAndComponents.map((item, index) => (
				<TabPanel
					key={index}
					value={value}
					index={index}
					style={{
						height: "100%",
						flexGrow: 1,
						overflow: "hidden",
						background: "#F2F8FA",
					}}
				>
					{item.component} {/* Display imported component */}
				</TabPanel>
			))}

			{/* Overlay Box */}
			{dialogOpen && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						height: boxHeight,
						backgroundColor: "rgba(255, 255, 255, 0.9)",
						zIndex: 10,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						p: 2,
						border: "1px solid #ccc",
						...props.style,
					}}
				>
					<Box
						sx={{
							overflowY: "scroll",
							backgroundColor: "white",
							borderRadius: 1,
							boxShadow: 2,
							p: 3,
							minwidth: "90%",
							maxwidth: "90%",
							minHeight: "90%",
							maxHeight: "90%",
						}}
					>
						<OverlayContent />
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default MiniNav;
